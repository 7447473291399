import { useCallback } from 'react';

export default function useRequestCallback(token?: string | null) {
  const request = useCallback(
    async <T = any>(url: string, init: RequestInit = {}) => {
      if (!token) return;
      const { headers, ...initRest } = init;

      const response = await fetch(`/api/v1${url}`, {
        ...initRest,
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ''
        }
      });

      return response.json() as Promise<T>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  return request;
}
