/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
import React from 'react';

import { ThemeProvider } from '@bljs/blui';
import { App } from 'app';
import { AuthProvider } from 'app/contexts/AuthContext';
import { RequestProvider } from 'app/contexts/RequestContext';
import { store } from 'app/store';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from 'reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider>
        <ThemeProvider>
          <HelmetProvider>
            <Provider store={store}>
              <RequestProvider>
                <App />
                <ToastContainer
                  position="top-right"
                  autoClose={2500}
                  hideProgressBar
                  theme="colored"
                  newestOnTop
                  pauseOnHover
                  style={{ zIndex: 1000001 }}
                />
              </RequestProvider>
            </Provider>
          </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
    </React.StrictMode>,
    MOUNT_NODE
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./', renderApp);
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
