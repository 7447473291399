// ConceptList
export const START_FETCH_CONCEPT_LIST = 'START_FETCH_CONCEPT_LIST';
export const FETCH_CONCEPT_LIST_SUCCESS = 'FETCH_CONCEPT_LIST_SUCCESS';
export const FETCH_CONCEPT_LIST_FAILED = 'FETCH_CONCEPT_LIST_FAILED';
export const CLEAN_CONCEPT_LIST = 'CLEAN_CONCEPT_LIST';
export const REMOVE_CONCEPT_FROM_LIST = 'REMOVE_CONCEPT_FROM_LIST';

export const START_SEARCH_CONCEPTS = 'START_SEARCH_CONCEPTS';
export const FETCH_SEARCH_CONCEPTS_SUCCESS = 'FETCH_SEARCH_CONCEPTS_SUCCESS';
export const FETCH_SEARCH_CONCEPTS_FAILED = 'FETCH_SEARCH_CONCEPTS_FAILED';

export const START_PUBLISHED_FETCH_CONCEPT_LIST = 'START_PUBLISHED_FETCH_CONCEPT_LIST';
export const FETCH_PUBLISHED_CONCEPT_LIST_FAILED = 'FETCH_PUBLISHED_CONCEPT_LIST_FAILED';
export const FETCH_PUBLISHED_CONCEPT_LIST_SUCCESS = 'FETCH_PUBLISHED_CONCEPT_LIST_SUCCESS';

export const START_DRAFT_FETCH_CONCEPT_LIST = 'START_DRAFT_FETCH_CONCEPT_LIST';
export const FETCH_DRAFT_CONCEPT_LIST_FAILED = 'FETCH_DRAFT_CONCEPT_LIST_FAILED';
export const FETCH_DRAFT_CONCEPT_LIST_SUCCESS = 'FETCH_DRAFT_CONCEPT_LIST_SUCCESS';

// CONCEPT - information about concept
export const START_FETCH_CONCEPT = 'START_FETCH_CONCEPT';
export const FETCH_CONCEPT_SUCCESS = 'FETCH_CONCEPT_SUCCESS';
export const FETCH_CONCEPT_FAILED = 'FETCH_CONCEPT_FAILED';
export const CLEAN_CONCEPT = 'CLEAN_CONCEPT';
export const START_PUBLISH_CONCEPT = 'START_PUBLISH_CONCEPT';
export const CONCEPT_PUBLISHED_SUCCESS = 'CONCEPT_PUBLISHED_SUCCESS';
export const CONCEPT_PUBLISHED_FAILED = 'CONCEPT_PUBLISHED_FAILED';
export const CHANGE_CONCEPT_DATA = 'CHANGE_CONCEPT_DATA';
export const FETCH_TRIGGERED_DATA_STARTED = 'FETCH_TRIGGERED_DATA_STARTED';

export const FETCH_ACKNOWLEDGEMENTS_SUCCESS = 'FETCH_ACKNOWLEDGEMENTS_SUCCESS';
export const FETCH_CONCEPT_ACKNOWLEDGE = 'FETCH_CONCEPT_ACKNOWLEDGE';
export const UPDATE_ACKNOWLEDGE_LIST = 'UPDATE_ACKNOWLEDGE_LIST';
export const FETCH_COMMON_TAGS_DATA_SUCCESS = 'FETCH_COMMON_TAGS_DATA_SUCCESS';
export const START_FETCH_COMMON_TAGS_DATA = 'START_FETCH_COMMON_TAGS_DATA';
// POPUP
export const OPEN_CONFIRM_POPUP = 'OPEN_CONFIRM_POPUP';
export const CLOSE_CONFIRM_POPUP = 'CLOSE_CONFIRM_POPUP';

// ConceptTestReducer
export const START_FETCH_HISTORICAL_DATA = 'START_FETCH_HISTORICAL_DATA';
export const FETCH_HISTORICAL_DATA_SUCCESS = 'FETCH_HISTORICAL_DATA_SUCCESS';
export const FETCH_HISTORICAL_DATA_FAILED = 'FETCH_HISTORICAL_DATA_FAILED';
export const CLEAR_HISTORICAL_DATA = 'CLEAR_HISTORICAL_DATA';
export const CLEAR_TEST_RESULT = 'CLEAR_TEST_RESULT';
export const START_FETCH_TEST_RESULT = 'START_FETCH_TEST_RESULT';
export const FETCH_TEST_RESULT_SUCCESS = 'FETCH_TEST_RESULT_SUCCESS';
export const FETCH_TEST_RESULT_FAILED = 'FETCH_TEST_RESULT_FAILED';
export const FETCH_TEST_RESULT_CANCEL = 'FETCH_TEST_RESULT_CANCEL';

export const SET_TEST_RESULT_TRIGGERED_ITEM = 'SET_TEST_RESULT_TRIGGERED_ITEM';

// Triggered Concept List

export const START_FETCH_TRIGGERED_CONCEPT_LIST = 'START_FETCH_TRIGGERED_CONCEPT_LIST';
export const FETCH_TRIGGERED_CONCEPT_LIST_SUCCESS = 'FETCH_TRIGGERED_CONCEPT_LIST_SUCCESS';
export const FETCH_TRIGGERED_CONCEPT_LIST_FAILED = 'FETCH_TRIGGERED_CONCEPT_LIST_FAILED';
export const CLEAN_TRIGGERED_CONCEPT_LIST = 'CLEAN_TRIGGERED_CONCEPT_LIST';

// Auth
export const SET_TOKEN = 'SET_TOKEN';
export const SET_BEYOND_LIMITS_TOKEN = 'SET_BEYOND_LIMITS_TOKEN';
export const SET_BEYOND_LIMITS_ROLES = 'SET_BEYOND_LIMITS_ROLES';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';

// Colors
export const START_GETTING_COLORS = 'START_GETTING_COLORS';
export const GET_COLORS_SUCCESS = 'GET_COLORS_SUCCESS';
export const GET_COLORS_FAILED = 'GET_COLORS_FAILED';

// Users
export const START_GETTING_USERS = 'START_GETTING_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_USERS_ROLES = 'SET_USERS_ROLES';
export const SET_USERS_UPLOAD_RESULT = 'SET_USERS_UPLOAD_RESULT';
export const SET_ORGANIZATIONAL_UNITS = 'SET_ORGANIZATIONAL_UNITS';

// Assets
export const START_GETTING_ASSET = 'START_GETTING_ASSET';
export const GET_ASSET_FAILED = 'GET_ASSET_FAILED';
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export const CLEAR_ASSET = 'CLEAR_ASSET';

// Trigger points
export const FETCH_TRIGGERED_DATA_SUCCESS = 'FETCH_TRIGGERED_DATA_SUCCESS';
export const FETCH_TRIGGERED_DATA_FAILED = 'FETCH_TRIGGERED_DATA_FAILED';
export const SELECT_TRIGGER_ITEM = 'SELECT_TRIGGER_ITEM';

// Correlation
export const START_FETCH_CORRELATING_TAGS = 'START_FETCH_CORRELATING_TAGS';
export const FETCH_CORRELATING_TAGS_SUCCESS = 'FETCH_CORRELATING_TAGS_SUCCESS';
export const FETCH_CORRELATING_TAGS_SUCCESS_AND_FINISH_SOCKET =
  'FETCH_CORRELATING_TAGS_SUCCESS_AND_FINISH_SOCKET';
export const FETCH_CORRELATING_TAGS_FAILED = 'FETCH_CORRELATING_TAGS_FAILED';
export const SHOW_MORE_CORRELATION_TAGS = 'SHOW_MORE_CORRELATION_TAGS';
export const UPDATE_TARGET_TAG = 'UPDATE_TARGET_TAG';
export const ADD_CORRELATION_TAG = 'ADD_CORRELATION_TAG';
export const CLEAR_CORRELATION_DATA = 'CLEAR_CORRELATION_DATA';
export const CLEAR_SELECTED_CORRELATION_TAGS = 'CLEAR_SELECTED_CORRELATION_TAGS';
export const CLEAR_ALL_CORRELATION = 'CLEAR_ALL_CORRELATION';
export const UPDATE_CORRELATION_TAGS = 'UPDATE_CORRELATION_TAGS';
export const SET_CORRELATION_SUBSCRIPTION_ID = 'SET_CORRELATION_SUBSCRIPTION_ID';
export const UPDATE_RELATED_TAGS = 'UPDATE_RELATED_TAGS';
export const CLEAR_RELATED_TAGS = 'CLEAR_RELATED_TAGS';

// Groups
export const START_FETCH_GROUP_LIST = 'START_FETCH_GROUP_LIST';
export const FETCH_GROUP_LIST_SUCCESS = 'FETCH_GROUP_LIST_SUCCESS';
export const FETCH_GROUP_LIST_FAILED = 'FETCH_GROUP_LIST_FAILED';
export const CLEAN_GROUP_LIST = 'CLEAN_GROUP_LIST';

export const START_FETCH_RELATED_GROUP_LIST = 'START_FETCH_RELATED_GROUP_LIST';
export const FETCH_RELATED_GROUP_LIST_SUCCESS = 'FETCH_RELATED_GROUP_LIST_SUCCESS';
export const FETCH_RELATED_GROUP_LIST_FAILED = 'FETCH_RELATED_GROUP_LIST_FAILED';

export const START_CREATE_GROUP = 'START_CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const START_FETCH_SIMILAR_GROUP_LIST = 'START_FETCH_SIMILAR_GROUP_LIST';
export const FETCH_SIMILAR_GROUP_LIST_SUCCESS = 'FETCH_SIMILAR_GROUP_LIST_SUCCESS';
export const FETCH_SIMILAR_GROUP_LIST_FAILED = 'FETCH_SIMILAR_GROUP_LIST_FAILED';

export const SIMILAR_GROUP_SUBSCRIBED = 'SIMILAR_GROUP_SUBSCRIBED';
export const SIMILAR_GROUP_UNSUBSCRIBED = 'SIMILAR_GROUP_UNSUBSCRIBED';
export const RELATED_GROUP_SUBSCRIBED = 'RELATED_GROUP_SUBSCRIBED';
export const RELATED_GROUP_UNSUBSCRIBED = 'RELATED_GROUP_UNSUBSCRIBED';
export const GROUP_UNSUBSCRIBED = 'GROUP_UNSUBSCRIBED';

export const SET_NAVIGATOR_FUNCTION = 'SET_NAVIGATOR_FUNCTION';

export const GET_NOTIFICATIONS_COUNT_FAILED = 'GET_NOTIFICATIONS_COUNT_FAILED';
export const START_GETTING_NOTIFICATIONS_COUNT = 'START_GETTING_NOTIFICATIONS_COUNT';
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS';

export const SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION';

export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';
export const START_GETTING_NOTIFICATIONS = 'START_GETTING_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const GET_CONCEPTS_STATISTIC_FAILED = 'GET_CONCEPTS_STATISTIC_FAILED';
export const START_GETTING_CONCEPTS_STATISTIC = 'START_GETTING_CONCEPTS_STATISTIC';
export const GET_CONCEPTS_STATISTIC_SUCCESS = 'GET_CONCEPTS_STATISTIC_SUCCESS';
export const CHANGE_SHOW_ALL_NOTIFICATIONS = 'CHANGE_SHOW_ALL_NOTIFICATIONS';

export const GET_CONCEPT_DETAILED_STATISTIC_FAILED = 'GET_CONCEPT_DETAILED_STATISTIC_FAILED';
export const START_GETTING_CONCEPT_DETAILED_STATISTIC = 'START_GETTING_CONCEPT_DETAILED_STATISTIC';
export const GET_CONCEPT_DETAILED_STATISTIC_SUCCESS = 'GET_CONCEPT_DETAILED_STATISTIC_SUCCESS';

// Feedback
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const REMOVE_FEEDBACK = 'REMOVE_FEEDBACK';
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS';
export const FETCH_FEEDBACKS_FAILED = 'FETCH_FEEDBACKS_FAILED';
export const START_FETCH_FEEDBACKS = 'START_FETCH_FEEDBACKS';

export const UPDATE_CONCEPT_COMMENTS = 'UPDATE_CONCEPT_COMMENTS';
export const UPDATE_RULE_COMMENTS = 'UPDATE_RULE_COMMENTS';
export const UPDATE_ACTION_COMMENTS = 'UPDATE_ACTION_COMMENTS';

export const FETCH_RELATED_EVENTS_SUCCESS = 'FETCH_RELATED_EVENTS_SUCCESS';
export const FETCH_RELATED_EVENTS_FAILED = 'FETCH_RELATED_EVENTS_FAILED';
export const START_FETCH_RELATED_EVENTS = 'START_FETCH_RELATED_EVENTS';

export const FETCH_KEYWORD_GROUPS_SUCCESS = 'FETCH_KEYWORD_GROUPS_SUCCESS';
export const FETCH_KEYWORD_GROUPS_FAILED = 'FETCH_KEYWORD_GROUPS_FAILED';
export const START_KEYWORD_GROUPS = 'START_KEYWORD_GROUPS';

export const FETCH_KEYWORD_GROUPS_SUGGESTIONS_SUCCESS = 'FETCH_KEYWORD_GROUPS_SUGGESTIONS_SUCCESS';
export const CLEAN_KEYWORD_GROUPS_SUGGESTIONS = 'CLEAN_KEYWORD_GROUPS_SUGGESTIONS';

export const UPDATE_ACTION_SUCCESS = 'UPDATE_ACTION_SUCCESS';

// AI Search
export const TOGGLE_AI_SEARCH = 'TOGGLE_AI_SEARCH';
export const CLEAR_AI_SEARCH = 'CLEAR_AI_SEARCH';
export const SET_AI_SEARCH_TOKEN = 'SET_AI_SEARCH_TOKEN';
export const SET_AI_SEARCH_INDICIES = 'SET_AI_SEARCH_INDICIES';
export const CHANGE_AI_SEARCH_INDICIES = 'CHANGE_AI_SEARCH_INDICIES';
export const AI_SEARCH_ADD_MESSAGE = 'AI_SEARCH_ADD_MESSAGE';
export const SET_AI_SEARCH_SESSION_ID = 'SET_AI_SEARCH_SESSION_ID';
export const AI_SEARCH_GET_ANSWER_STARTED = 'AI_SEARCH_GET_ANSWER_STARTED';
export const AI_SEARCH_GET_ANSWER_FAILED = 'AI_SEARCH_GET_ANSWER_FAILED';
export const AI_SEARCH_GET_ANSWER_SUCCEED = 'AI_SEARCH_GET_ANSWER_SUCCEED';

export const SET_RULES_VALIDATION_ERRORS = 'SET_RULES_VALIDATION_ERRORS';
export const CLEAR_RULES_VALIDATION_ERRORS = 'CLEAR_RULES_VALIDATION_ERRORS';
