import {
  FETCH_CORRELATING_TAGS_FAILED,
  FETCH_CORRELATING_TAGS_SUCCESS,
  START_FETCH_CORRELATING_TAGS,
  UPDATE_TARGET_TAG,
  ADD_CORRELATION_TAG,
  CLEAR_CORRELATION_DATA,
  CLEAR_SELECTED_CORRELATION_TAGS,
  CLEAR_ALL_CORRELATION,
  UPDATE_CORRELATION_TAGS,
  SET_CORRELATION_SUBSCRIPTION_ID,
  FETCH_CORRELATING_TAGS_SUCCESS_AND_FINISH_SOCKET,
  SHOW_MORE_CORRELATION_TAGS,
  UPDATE_RELATED_TAGS,
  CLEAR_RELATED_TAGS
} from '../constants';

const DEFAULT_CORRELATION_TAGS_SHOWN = 10;
const CORRELATION_TAGS_STEP = 10;

const correlationDefaultState = {
  loading: false,
  targetTag: [],
  correlationData: {
    findCorrelationsByNameResponses: [],
    attributeIdMap: {}
  },
  correlationTagsShown: DEFAULT_CORRELATION_TAGS_SHOWN,
  selectedCorrelationTags: [],
  correlationSubscriptionId: '',
  isSocketFinished: false,
  relatedTags: []
};

const reducer = (state = correlationDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_CORRELATING_TAGS:
      return {
        ...state,
        loading: true,
        isSocketFinished: false,
        correlationTagsShown: DEFAULT_CORRELATION_TAGS_SHOWN
      };
    case FETCH_CORRELATING_TAGS_SUCCESS:
      return {
        ...state,
        correlationData: action.payload,
        loading: false
      };
    case FETCH_CORRELATING_TAGS_SUCCESS_AND_FINISH_SOCKET:
      return {
        ...state,
        correlationData: action.payload,
        loading: false,
        isSocketFinished: true
      };
    case FETCH_CORRELATING_TAGS_FAILED:
      return {
        ...state,
        loading: false
      };
    case UPDATE_TARGET_TAG:
      return {
        ...state,
        targetTag: action.payload
      };
    case ADD_CORRELATION_TAG:
      return {
        ...state,
        selectedCorrelationTags: action.payload
      };
    case UPDATE_CORRELATION_TAGS:
      return {
        ...state,
        selectedCorrelationTags: action.payload
      };
    case CLEAR_CORRELATION_DATA:
      return {
        ...state,
        correlationData: {
          findCorrelationsByNameResponses: [],
          attributeIdMap: {}
        }
      };
    case CLEAR_SELECTED_CORRELATION_TAGS:
      return {
        ...state,
        selectedCorrelationTags: []
      };
    case CLEAR_ALL_CORRELATION:
      return {
        ...correlationDefaultState
      };
    case SET_CORRELATION_SUBSCRIPTION_ID:
      return {
        ...state,
        correlationSubscriptionId: action.payload
      };
    case SHOW_MORE_CORRELATION_TAGS:
      return {
        ...state,
        correlationTagsShown: state.correlationTagsShown + CORRELATION_TAGS_STEP
      };
    case UPDATE_RELATED_TAGS:
      return {
        ...state,
        relatedTags: action.payload
      };
    case CLEAR_RELATED_TAGS:
      return {
        ...state,
        relatedTags: []
      };
    default:
      return state;
  }
};

export default reducer;
