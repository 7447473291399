import {
  START_FETCH_CONCEPT_LIST,
  CLEAN_CONCEPT_LIST,
  FETCH_CONCEPT_LIST_SUCCESS,
  FETCH_CONCEPT_LIST_FAILED,
  REMOVE_CONCEPT_FROM_LIST,
  START_SEARCH_CONCEPTS,
  FETCH_SEARCH_CONCEPTS_FAILED,
  FETCH_SEARCH_CONCEPTS_SUCCESS,
  FETCH_PUBLISHED_CONCEPT_LIST_SUCCESS,
  FETCH_DRAFT_CONCEPT_LIST_SUCCESS
} from '../constants';
import { IConcept } from '../types';

const conceptListDefaultState = {
  loaded: false,
  firstLoad: false,
  items: [],
  totalItems: 0,
  totalFirstLoadItem: 0,
  publishedConcepts: [],
  draftConcepts: []
};

const reducer = (state = conceptListDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_CONCEPT_LIST:
    case START_SEARCH_CONCEPTS:
      return {
        ...state,
        items: [],
        loaded: false
      };
    case FETCH_CONCEPT_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        firstLoad: true,
        items: action.payload.items,
        totalItems: action.payload.totalItems,
        totalFirstLoadItem:
          action.payload.totalItems >= state.totalFirstLoadItem
            ? action.payload.totalItems
            : state.totalFirstLoadItem
      };
    case FETCH_CONCEPT_LIST_FAILED:
      return {
        ...state,
        loaded: true,
        firstLoad: true
      };
    case FETCH_SEARCH_CONCEPTS_SUCCESS:
      return {
        ...state,
        loaded: true,
        items: action.payload.content,
        totalItems: action.payload.totalElements,
        firstLoad: true
      };
    case FETCH_SEARCH_CONCEPTS_FAILED:
      return {
        ...state,
        loaded: true
      };
    case REMOVE_CONCEPT_FROM_LIST: {
      const {
        payload: { id }
      } = action;
      return {
        ...state,
        items: state.items.filter((item: IConcept) => item.id !== id),
        draftConcepts: state.draftConcepts.filter((item: IConcept) => item.id !== id),
        publishedConcepts: state.publishedConcepts.filter((item: IConcept) => item.id !== id),
        totalItems: state.totalItems - 1,
        totalFirstLoadItem: state.totalFirstLoadItem - 1,
        loaded: true
      };
    }
    case CLEAN_CONCEPT_LIST:
      return conceptListDefaultState;
    case FETCH_PUBLISHED_CONCEPT_LIST_SUCCESS:
      return {
        ...state,
        publishedConcepts: action.payload.items
      };
    case FETCH_DRAFT_CONCEPT_LIST_SUCCESS:
      return {
        ...state,
        draftConcepts: action.payload.items
      };

    default:
      return state;
  }
};

export default reducer;
