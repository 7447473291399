import {
  SET_AI_SEARCH_TOKEN,
  TOGGLE_AI_SEARCH,
  SET_AI_SEARCH_INDICIES,
  AI_SEARCH_ADD_MESSAGE,
  SET_AI_SEARCH_SESSION_ID,
  CLEAR_AI_SEARCH,
  CHANGE_AI_SEARCH_INDICIES,
  AI_SEARCH_GET_ANSWER_STARTED,
  AI_SEARCH_GET_ANSWER_FAILED,
  AI_SEARCH_GET_ANSWER_SUCCEED
} from '../constants';

export enum AI_MESSAGE_TYPE {
  AI,
  USER
}

interface IASearchState {
  isOpen: boolean;
  token: string;
  messages: any[];
  isWaiting: boolean;
  indices: any[];
  sessionId: string;
  isError: boolean;
}

const defaultState: IASearchState = {
  isOpen: false,
  token: localStorage.getItem('AI_SEARCH_TOKEN') || '',
  messages: [],
  isWaiting: false,
  indices: [],
  sessionId: '',
  isError: false
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_AI_SEARCH:
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case SET_AI_SEARCH_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case SET_AI_SEARCH_INDICIES:
      return {
        ...state,
        indices: action.payload
      };
    case AI_SEARCH_ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case AI_SEARCH_GET_ANSWER_STARTED:
      return {
        ...state,
        isWaiting: true,
        isError: false
      };
    case AI_SEARCH_GET_ANSWER_FAILED:
      return {
        ...state,
        isWaiting: false,
        isError: true
      };
    case AI_SEARCH_GET_ANSWER_SUCCEED:
      return {
        ...state,
        isWaiting: false
      };
    case SET_AI_SEARCH_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      };
    case CLEAR_AI_SEARCH:
      return {
        ...state,
        messages: [],
        sessionId: '',
        isError: false
      };
    case CHANGE_AI_SEARCH_INDICIES:
      return {
        ...state,
        indices: state.indices.map(item => {
          const changed = action.payload.find(currentItem => item.index_name === currentItem.key);

          return { ...item, selected: changed.selected };
        })
      };

    default:
      return state;
  }
};

export default reducer;
