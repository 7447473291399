import {
  START_FETCH_HISTORICAL_DATA,
  FETCH_HISTORICAL_DATA_SUCCESS,
  FETCH_HISTORICAL_DATA_FAILED,
  START_FETCH_TEST_RESULT,
  FETCH_TEST_RESULT_SUCCESS,
  FETCH_TEST_RESULT_FAILED,
  FETCH_TEST_RESULT_CANCEL,
  CLEAR_HISTORICAL_DATA,
  CLEAR_TEST_RESULT,
  SET_TEST_RESULT_TRIGGERED_ITEM
} from '../constants';

interface ITestConcept {
  loadingHistoricalData: boolean;
  loadingTestResult: boolean;
  historicalData: any;
  testResultData: any;
  isTestCanceled: boolean;
  changedMark: string;
}

const conceptTestDefaultState: ITestConcept = {
  loadingHistoricalData: false,
  loadingTestResult: false,
  historicalData: null,
  testResultData: null,
  isTestCanceled: false,
  changedMark: ''
};

const reducer = (state = conceptTestDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_HISTORICAL_DATA:
      return {
        ...state,
        loadingHistoricalData: true
      };
    case FETCH_HISTORICAL_DATA_SUCCESS:
      return {
        ...state,
        loadingHistoricalData: false,
        historicalData: action.payload
      };
    case FETCH_HISTORICAL_DATA_FAILED:
      return {
        ...state,
        loadingHistoricalData: false,
        historicalData: null
      };
    case START_FETCH_TEST_RESULT:
      return {
        ...state,
        loadingHistoricalData: false,
        loadingTestResult: true,
        isTestCanceled: false
      };
    case FETCH_TEST_RESULT_SUCCESS:
      return {
        ...state,
        loadingTestResult: false,
        testResultData: {
          ...action.payload,
          evaluations: action.payload.evaluations.map(item => ({ ...item, selected: false }))
        }
      };
    case FETCH_TEST_RESULT_FAILED:
      return {
        ...state,
        loadingTestResult: false
      };
    case FETCH_TEST_RESULT_CANCEL:
      return {
        ...state,
        isTestCanceled: true,
        loadingTestResult: false
      };
    case CLEAR_HISTORICAL_DATA:
      return {
        ...state,
        historicalData: null
      };
    case CLEAR_TEST_RESULT:
      return {
        ...state,
        testResultData: null
      };
    case SET_TEST_RESULT_TRIGGERED_ITEM: {
      return {
        ...state,
        testResultData: {
          ...state.testResultData,
          evaluations: state.testResultData.evaluations.map(item =>
            item.step === action.payload.step
              ? { ...item, selected: true }
              : { ...item, selected: false }
          )
        },
        changedMark: String(Math.random())
      };
    }
    default:
      return state;
  }
};

export default reducer;
