import { combineReducers } from 'redux';

import { feedbackApi } from '../services/feedbackApi';
import AISearchReducer from './AISearchReducer';
import assetReducer from './assetReducer';
import authReducer from './authReducer';
import colorReducer from './colorReducer';
import conceptListReducer from './conceptListReducer';
import conceptReducer from './conceptReducer';
import conceptRelatedEventsReducer from './conceptRelatedEventsReducer';
import conceptTestReducer from './conceptTestReducer';
import correlationReducer from './correlationReducer';
import feedbackReducer from './feedbackReducer';
import groupReducer from './groupReducer';
import keywordGroupsReducer from './keywordGroupsReducer';
import navigatorReducer from './navigatorReducer';
import notificationReducer from './notificationReducer';
import popupReducer from './popupReducer';
import triggeredConceptListReducer from './triggeredConceptListReducer';
import userReducer from './userReducer';

export default combineReducers({
  conceptListReducer,
  conceptReducer,
  popupReducer,
  conceptTestReducer,
  triggeredConceptListReducer,
  authReducer,
  colorReducer,
  userReducer,
  assetReducer,
  correlationReducer,
  groupReducer,
  navigatorReducer,
  notificationReducer,
  feedbackReducer,
  conceptRelatedEventsReducer,
  keywordGroupsReducer,
  AISearchReducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer
});
