import { createContext, useCallback, useEffect, useState } from 'react';

import { GetTokenSilentlyOptions, useAuth } from 'app/contexts/AuthContext';

import useRequestCallback from './useRequestCallback';

interface RequestContextValue {
  token: string | null;
  request: <T = any>(url: string, init?: RequestInit) => Promise<T | undefined>;
}

export const RequestContext = createContext({} as RequestContextValue);

export function RequestProvider({ children }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const request = useRequestCallback(token);

  const getToken = useCallback(
    async (options?: GetTokenSilentlyOptions) => {
      if (!isAuthenticated) return;
      try {
        setToken(await getAccessTokenSilently(options));
      } catch (err) {
        console.error(err);
      }
    },
    [getAccessTokenSilently, isAuthenticated]
  );

  useEffect(() => {
    getToken();
  }, [getToken]);

  const value: RequestContextValue = {
    token,
    request
  };

  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
}
