import { Auth0ProviderOptions } from '@auth0/auth0-react';

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_REDIRECT_URI, WEB_API_IDENTIFIER } =
  window.__RUNTIME_CONFIG__;

const authConfig: Auth0ProviderOptions = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  redirectUri: AUTH0_REDIRECT_URI,
  audience: WEB_API_IDENTIFIER,
  onRedirectCallback: appState => {
    window.location.replace(appState?.redirect_to || '/');
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage'
};

export default authConfig;
