import {
  FETCH_KEYWORD_GROUPS_SUCCESS,
  FETCH_KEYWORD_GROUPS_FAILED,
  START_KEYWORD_GROUPS,
  FETCH_KEYWORD_GROUPS_SUGGESTIONS_SUCCESS,
  CLEAN_KEYWORD_GROUPS_SUGGESTIONS
} from '../constants';

const keywordGroupsDefaultState = {
  loading: false,
  keywordGroups: [],
  suggestions: []
};

const reducer = (state = keywordGroupsDefaultState, action) => {
  switch (action.type) {
    case START_KEYWORD_GROUPS:
      return {
        ...state,
        loading: true
      };
    case FETCH_KEYWORD_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        keywordGroups: action.payload
      };
    case FETCH_KEYWORD_GROUPS_FAILED:
      return {
        ...state,
        loading: false
      };
    case FETCH_KEYWORD_GROUPS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.payload
      };
    case CLEAN_KEYWORD_GROUPS_SUGGESTIONS:
      return {
        ...state,
        suggestions: []
      };
    default:
      return state;
  }
};

export default reducer;
