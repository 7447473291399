import { ReactElement, useState, Dispatch, SetStateAction } from 'react';

import { FontIcon, IconButton } from '@fluentui/react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

type ToastErrorWrapperType = {
  message: string | ReactElement | JSX.Element;
  serviceError?: Record<string, any> | unknown;
};

const onCopyErrorToClipboard =
  (
    message: string | ReactElement | JSX.Element,
    setHasBeenCopied: Dispatch<SetStateAction<boolean>>,
    serviceError?: Record<string, any> | unknown
  ) =>
  () => {
    const isString = typeof message === 'string';
    let contentToCopy: string = 'An error occurred. Please Contact Support';

    if (serviceError && Object.keys(serviceError).length) {
      contentToCopy = JSON.stringify(serviceError);
    } else if (isString) {
      contentToCopy = message;
    }

    navigator.clipboard.writeText(contentToCopy);

    setHasBeenCopied(true);

    setTimeout(() => {
      setHasBeenCopied(false);
    }, 2000);
  };

export const ToastErrorWrapper = ({ message, serviceError }: ToastErrorWrapperType) => {
  const [hasBeenCopied, setHasBeenCopied] = useState(false);
  const iconName = hasBeenCopied ? 'Accept' : 'Copy';
  const content = typeof message === 'string' ? <span>{message}</span> : message;

  return (
    <ToastErrorWrapperDiv
      className="toast-error-wrapper"
      data-testid="toast-error-wrapper"
      data-automationid="toast-error-wrapper"
    >
      {content}{' '}
      <span className="toast-error-wrapper-icon-wrapper">
        <IconButton
          onClick={onCopyErrorToClipboard(message, setHasBeenCopied, serviceError)}
          iconProps={{ iconName }}
        />
      </span>
    </ToastErrorWrapperDiv>
  );
};

const ToastErrorWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  .toast-error-wrapper-icon-wrapper {
    padding: 5px 10px;

    & button {
      color: #ffffff;
      &:hover {
        background: inherit;
        transform: scale(1.1);
      }
    }
  }
`;

const CloseStyled = styled.div`
  overflow: visible;
  i {
    font-size: 10px;

    &:hover {
      transform: scale(1.05);
    }
  }

  .close-all {
    opacity: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 10px;
    min-width: 40px;
    padding: 1px 2px;
    border-radius: 10%;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &-visible {
      opacity: 1;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const CustomButton = ({ background }) => {
  const [closeAll, setCloseAll] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

  const onMouseEnter = () => {
    setCloseAll(true);

    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    setTimeoutRef(null);
  };

  const onMouseLeave = () => {
    const ref = setTimeout(() => {
      setCloseAll(false);
    }, 1500);
    setTimeoutRef(ref);
  };

  return (
    <CloseStyled onClick={() => toast.dismiss()}>
      <FontIcon
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ cursor: 'pointer' }}
        aria-label="accordian-icon"
        iconName="ChromeClose"
      />
      <div
        onClick={() => toast.dismiss()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ background: background }}
        className={`close-all ${closeAll ? 'close-all-visible' : ''}`}
      >
        Close All
      </div>
    </CloseStyled>
  );
};
