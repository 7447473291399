import { useEffect } from 'react';

import { noop } from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setNavigatorFunction } from '../store/actions/navigatorReducer';

const NavigateHighOrderComponent = ({ onSetNavigatorFunction, children, navigateFunction }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateFunction === noop) {
      onSetNavigatorFunction(navigate);
    }
  }, []);

  return children;
};

const mapStateToProps = ({ navigatorReducer }) => ({
  navigateFunction: navigatorReducer.navigate
});

const mapDispatchToProps = dispatch => ({
  onSetNavigatorFunction: navigate => dispatch(setNavigatorFunction(navigate))
});

const NavigationHOC = connect(mapStateToProps, mapDispatchToProps)(NavigateHighOrderComponent);

export default NavigationHOC;
