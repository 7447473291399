import {
  START_FETCH_TRIGGERED_CONCEPT_LIST,
  FETCH_TRIGGERED_CONCEPT_LIST_SUCCESS,
  FETCH_TRIGGERED_CONCEPT_LIST_FAILED,
  CLEAN_TRIGGERED_CONCEPT_LIST
} from '../constants';

const triggeredConceptListDefaultState = {
  loading: false,
  items: [],
  totalItems: 0,
  firstLoad: false
};

const reducer = (state = triggeredConceptListDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_TRIGGERED_CONCEPT_LIST:
      return {
        ...state,
        items: [],
        loading: true
      };
    case FETCH_TRIGGERED_CONCEPT_LIST_SUCCESS:
      return {
        loading: false,
        items: action.payload.items,
        totalItems: action.payload.totalItems,
        firstLoad: true
      };
    case FETCH_TRIGGERED_CONCEPT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        firstLoad: true
      };
    case CLEAN_TRIGGERED_CONCEPT_LIST:
      return {
        ...state,
        items: []
      };

    default:
      return state;
  }
};

export default reducer;
