import { configureStore } from '@reduxjs/toolkit';
import { feedbackApi } from 'app/store/services/feedbackApi';
import logger from 'redux-logger';

import rootReducer from './reducers/index';

const middlewares = [feedbackApi.middleware];
const onlyDevelopment = process.env.NODE_ENV === 'development';

if (onlyDevelopment) {
  middlewares.push(logger);
}

// Enable hot-reloading
// https://redux.js.org/usage/configuring-your-store#hot-reloading
if (onlyDevelopment && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: onlyDevelopment
});
