import { displayToastError } from '../../../utils/common/helpers';
import { waitForHeaders } from '../../../utils/createAuthHeaders';
import { http } from '../../BaseApi';
import {
  GET_NOTIFICATIONS_COUNT_FAILED,
  START_GETTING_NOTIFICATIONS_COUNT,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCESS,
  START_GETTING_NOTIFICATIONS,
  GET_CONCEPTS_STATISTIC_FAILED,
  START_GETTING_CONCEPTS_STATISTIC,
  GET_CONCEPTS_STATISTIC_SUCCESS,
  GET_CONCEPT_DETAILED_STATISTIC_SUCCESS,
  GET_CONCEPT_DETAILED_STATISTIC_FAILED,
  START_GETTING_CONCEPT_DETAILED_STATISTIC,
  CHANGE_SHOW_ALL_NOTIFICATIONS,
  SET_NOTIFICATIONS_PAGINATION
} from '../constants';
import { getSortQuery } from './shared';

const UNAUTHORIZED_ERROR_CODE = 401;

export const getNotificationsCountFailed = () => ({
  type: GET_NOTIFICATIONS_COUNT_FAILED
});

const startGettingNotificationsCount = () => ({
  type: START_GETTING_NOTIFICATIONS_COUNT
});

export const getNotificationsCountSuccess = notificationsCount => ({
  type: GET_NOTIFICATIONS_COUNT_SUCCESS,
  payload: notificationsCount
});

export const getNewNotificationsCount = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGettingNotificationsCount());
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/notifications/new`, headers);
      dispatch(getNotificationsCountSuccess(res.data.notificationsCount));
    } catch (err: any) {
      if (err.response?.data?.status !== UNAUTHORIZED_ERROR_CODE) {
        displayToastError('Unable to retrieve information about the new notifications.', err);
      }
      dispatch(getNotificationsCountFailed());
    }
  };
};

export const getNotificationsFailed = () => ({
  type: GET_NOTIFICATIONS_FAILED
});

const startGettingNotifications = () => ({
  type: START_GETTING_NOTIFICATIONS
});

export const getNotificationsSuccess = notificationsCount => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notificationsCount
});

export const getConceptStatisticFailed = () => ({
  type: GET_CONCEPTS_STATISTIC_FAILED
});

export const getNewNotifications = (
  pageNumber = 0,
  sortOrder = [{ sortKey: 'createdAt', isAscending: false }]
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGettingNotifications());
      const sortQuery = getSortQuery(sortOrder);
      const headers = await waitForHeaders(getState);
      const { showAllNotifications } = getState().notificationReducer;
      const url = sortQuery.length
        ? `/api/notifications?sort=${getSortQuery(sortOrder).join(
            '|'
          )}&pageNumber=${pageNumber}&showAll=${showAllNotifications}`
        : `/api/notifications?pageNumber=${pageNumber}&showAll=${showAllNotifications}`;
      const res = await http.get(url, headers);

      dispatch(getNotificationsSuccess(res.data));
    } catch (err) {
      dispatch(getNotificationsFailed());
      displayToastError('Unable to retrieve information about the new notifications.', err);
    }
  };
};

export const changeShowAllNotifications = isShowAllNotifications => ({
  type: CHANGE_SHOW_ALL_NOTIFICATIONS,
  payload: isShowAllNotifications
});

export const markOneNotificationAsRead = (notificationId: string) => {
  return async (dispatch, getState) => {
    try {
      const headers = await waitForHeaders(getState);

      await http.post(`/api/notifications/read/${notificationId}`, {}, headers);

      const notifications = getState().notificationReducer.notifications.map(notification =>
        notification.id === notificationId ? { ...notification, viewed: true } : notification
      );

      dispatch(
        getNotificationsSuccess({
          content: notifications,
          totalElements: getState().notificationReducer.notificationsCount
        })
      );
    } catch (err) {
      displayToastError(`Unable to mark notification with ID: ${notificationId} as viewed.`, err);
    }
  };
};

export const markAllNotificationAsRead = () => {
  return async (dispatch, getState) => {
    try {
      const headers = await waitForHeaders(getState);

      await http.post(`/api/notifications/read`, {}, headers);

      const notifications = getState().notificationReducer.notifications.map(notification => ({
        ...notification,
        viewed: true
      }));

      dispatch(
        getNotificationsSuccess({
          content: notifications,
          totalElements: getState().notificationReducer.notificationsCount
        })
      );
    } catch (err) {
      displayToastError('Unable to mark all notifications as viewed.', err);
    }
  };
};

const startGettingConceptStatistic = () => ({
  type: START_GETTING_CONCEPTS_STATISTIC
});

export const getConceptStatisticSuccess = conceptsStatistic => ({
  type: GET_CONCEPTS_STATISTIC_SUCCESS,
  payload: conceptsStatistic
});

// TODO It's is unused now. But I leave it here. Most likely we will use it in the near future.
// TODO revise it in 3 month [ March 19th ]. If there is no any plans to use it - remove it and all related code [Actions, Reducer, constants]
export const getNotificationsStatistic = (
  sortOrder = [{ sortKey: 'createdAt', isAscending: false }],
  pageNumber = 0
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGettingConceptStatistic());
      const sortQuery = getSortQuery(sortOrder);
      const headers = await waitForHeaders(getState);
      const url = sortQuery.length
        ? `/api/concepts/statistics?sort=${getSortQuery(sortOrder).join(
            '|'
          )}&pageNumber=${pageNumber}`
        : `/api/concepts/statistics?pageNumber=${pageNumber}`;
      const res = await http.get(url, headers);

      dispatch(getConceptStatisticSuccess(res.data));
    } catch (err) {
      displayToastError('Unable to retrieve information about this concept.', err);
      dispatch(getConceptStatisticFailed());
    }
  };
};

export const getConceptDetailedStatisticFailed = () => ({
  type: GET_CONCEPT_DETAILED_STATISTIC_FAILED
});

const startGettingConceptDetailedStatistic = () => ({
  type: START_GETTING_CONCEPT_DETAILED_STATISTIC
});

export const getConceptDetailedStatisticSuccess = conceptDetailedStatistic => ({
  type: GET_CONCEPT_DETAILED_STATISTIC_SUCCESS,
  payload: conceptDetailedStatistic
});

export const getConceptDetailedStatistic = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGettingConceptDetailedStatistic());
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/concepts/statistics/${id}`, headers);

      dispatch(getConceptDetailedStatisticSuccess(res.data));
    } catch (err) {
      displayToastError('Unable to retrieve information about this concept.', err);
      dispatch(getConceptDetailedStatisticFailed());
    }
  };
};

export const setNotificationPagination = pageNumber => ({
  type: SET_NOTIFICATIONS_PAGINATION,
  payload: pageNumber
});
