import { noop } from 'lodash';

import { SET_NAVIGATOR_FUNCTION } from '../constants';

const userDefaultState = {
  navigate: noop
};

const reducer = (state = userDefaultState, action) => {
  switch (action.type) {
    case SET_NAVIGATOR_FUNCTION:
      return {
        ...state,
        navigate: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
