import {
  FETCH_FEEDBACKS_SUCCESS,
  FETCH_FEEDBACKS_FAILED,
  START_FETCH_FEEDBACKS
} from '../constants';

const feedbackDefaultState = {
  loading: false,
  feedbackData: {
    concept: {},
    rules: [],
    actions: []
  }
};

const reducer = (state = feedbackDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_FEEDBACKS:
      return {
        ...state,
        loading: true
      };
    case FETCH_FEEDBACKS_SUCCESS:
      return {
        loading: false,
        feedbackData: action.payload
      };
    case FETCH_FEEDBACKS_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
