import React, { lazy, Suspense } from 'react';

type Unpromisify<T> = T extends Promise<infer P> ? P : never;

interface Config<T, U> {
  fallback?: React.ReactNode;
  selector?: (s: Unpromisify<T>) => U;
}

export const lazyLoad = <T extends Promise<any>, U extends React.ComponentType<any>>(
  importFunc: () => T,
  config?: Config<T, U>
) => {
  const { fallback, selector } = { ...config };
  let lazyFactory: () => Promise<{ default: U }> = importFunc;

  if (selector) {
    lazyFactory = () =>
      importFunc().then(module => {
        // if Components are exported as default
        if (module.default) {
          return { default: module.default };
        }

        return { default: selector(module) };
      });
  }

  const LazyComponent = lazy(lazyFactory);

  return (props: React.ComponentProps<U>): JSX.Element => (
    <Suspense fallback={fallback || null}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
