import { OPEN_CONFIRM_POPUP, CLOSE_CONFIRM_POPUP } from '../constants';

const popupDefaultState = {
  isConfirmPopupOpen: false,
  title: '',
  message: '',
  callback: () => {}
};

const reducer = (state = popupDefaultState, action) => {
  switch (action.type) {
    case OPEN_CONFIRM_POPUP:
      return {
        ...state,
        isConfirmPopupOpen: true,
        title: action.payload.title,
        message: action.payload.message,
        callback: action.payload.callback
      };
    case CLOSE_CONFIRM_POPUP:
      return popupDefaultState;

    default:
      return state;
  }
};

export default reducer;
