import { createContext } from 'react';

import { Auth0Provider, User } from '@auth0/auth0-react';

import authConfig from './authConfig';

type UseAuthType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  getAccessTokenSilently: () => Promise<string>;
  user: User;
  logout: () => void;
  loginWithRedirect: () => Promise<void>;
};

interface AuthContextValue {
  hookUseAuthValues: UseAuthType;
}

export const AuthContext = createContext({} as AuthContextValue);

/**
 * Wrapper provider around Auth0Provider which conditionally either returns the Auth0Provider
 * or just a placeholder provider if we are skipping authentication. This wrapper should also let us
 * swap out different auth providers if desired, without needing to touch the rest of the codebase.
 */
export function AuthProvider({ children }) {
  // return Auth0Provider if we are in production or are not skipping auth
  if (
    process.env.NODE_ENV === 'production' ||
    !JSON.parse(window.__RUNTIME_CONFIG__.DANGER_SKIP_AUTH || 'false')
  ) {
    return <Auth0Provider {...authConfig}>{children}</Auth0Provider>;
  }

  // return placholder values if in non-production AND skipping auth
  const value = {
    hookUseAuthValues: {
      isAuthenticated: true,
      isLoading: false,
      getAccessTokenSilently: () => Promise.resolve('test token'),
      user: { name: 'AJ Abdallat' },
      logout: () => {
        window.location.replace('/login');
      },
      loginWithRedirect: () => {
        return Promise.resolve(window.location.replace('/'));
      }
    }
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
