import { waitForHeaders } from '../../../utils/createAuthHeaders';
import { http } from '../../BaseApi';
import { START_GETTING_COLORS, GET_COLORS_FAILED, GET_COLORS_SUCCESS } from '../constants';

export const getColorsFailed = () => ({
  type: GET_COLORS_FAILED
});

const startGettingColors = () => ({
  type: START_GETTING_COLORS
});

export const getColorsSuccess = colors => ({
  type: GET_COLORS_SUCCESS,
  payload: colors
});

export const getColors = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startGettingColors());
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/colors`, headers);
      dispatch(getColorsSuccess(res.data));
    } catch (e) {
      dispatch(getColorsFailed());
    }
  };
};
