import moment from 'moment';

import {
  START_FETCH_CONCEPT,
  FETCH_CONCEPT_SUCCESS,
  FETCH_CONCEPT_FAILED,
  CLEAN_CONCEPT,
  START_PUBLISH_CONCEPT,
  CHANGE_CONCEPT_DATA,
  CONCEPT_PUBLISHED_SUCCESS,
  CONCEPT_PUBLISHED_FAILED,
  FETCH_ACKNOWLEDGEMENTS_SUCCESS,
  FETCH_TRIGGERED_DATA_SUCCESS,
  FETCH_TRIGGERED_DATA_FAILED,
  UPDATE_CONCEPT_COMMENTS,
  UPDATE_RULE_COMMENTS,
  UPDATE_ACTION_COMMENTS,
  UPDATE_ACTION_SUCCESS,
  SELECT_TRIGGER_ITEM,
  FETCH_TRIGGERED_DATA_STARTED,
  FETCH_COMMON_TAGS_DATA_SUCCESS,
  START_FETCH_COMMON_TAGS_DATA,
  UPDATE_ACKNOWLEDGE_LIST,
  SET_RULES_VALIDATION_ERRORS,
  CLEAR_RULES_VALIDATION_ERRORS
} from '../constants';
import { IAction, IConceptRequest, IRule } from '../types';

const conceptDefaultState: IConceptRequest = {
  loading: false,
  loadingTriggeredData: false,
  data: {
    author: {
      id: 0,
      firstName: '',
      lastName: '',
      photoLink: '',
      jobTitle: ''
    },
    actions: [],
    createdAt: 0,
    colorId: 0,
    condition: 'or',
    description: '',
    enabled: false,
    name: '',
    notificationRecipients: [],
    publishedAt: 0,
    publishedStatus: false,
    rules: [],
    updatedAt: 0,
    variables: [],
    comments: []
  },
  // will probably have to change this in the future, not sure
  acknowledgedPeople: [],
  triggerData: [],
  evaluationsChangedMark: '',
  commonChartData: [],
  isLoadingCommonChartData: false,
  rulesErrors: []
};

const reducer = (state = conceptDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_CONCEPT:
    case START_PUBLISH_CONCEPT:
      return {
        ...state,
        loading: true
      };
    case FETCH_TRIGGERED_DATA_STARTED:
      return {
        ...state,
        loadingTriggeredData: true
      };
    case FETCH_CONCEPT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case CONCEPT_PUBLISHED_FAILED:
    case FETCH_CONCEPT_FAILED:
    case FETCH_TRIGGERED_DATA_FAILED:
      return {
        ...state,
        loading: false
      };
    case CLEAN_CONCEPT:
      return conceptDefaultState;
    case CHANGE_CONCEPT_DATA:
      const newData = action.data;
      return {
        ...state,
        data: { ...state.data, ...newData }
      };
    case CONCEPT_PUBLISHED_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          publishedStatus: true
        }
      };
    case UPDATE_ACKNOWLEDGE_LIST:
    case FETCH_ACKNOWLEDGEMENTS_SUCCESS:
      return {
        ...state,
        acknowledgedPeople: action.payload
      };
    case FETCH_TRIGGERED_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingTriggeredData: false,
        triggerData: action.payload.map(trigger => {
          return {
            isTriggered: true,
            step: moment(trigger.step).toISOString(),
            time: moment(trigger.step).toISOString(),
            rules: [trigger.ruleId],
            value: null,
            selected: false
          };
        })
      };
    }
    case SELECT_TRIGGER_ITEM: {
      return {
        ...state,
        triggerData: state.triggerData.map(item =>
          item.step === action.payload.step
            ? { ...item, selected: true }
            : { ...item, selected: false }
        ),
        evaluationsChangedMark: String(Math.random())
      };
    }
    case UPDATE_CONCEPT_COMMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          comments: action.payload
        }
      };
    }
    case UPDATE_RULE_COMMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          rules: state.data.rules.map((rule: IRule) =>
            rule.id === action.payload.ruleId
              ? { ...rule, comments: action.payload.comments }
              : rule
          )
        }
      };
    }
    case UPDATE_ACTION_COMMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          actions: state.data.actions.map((item: IAction) =>
            item.id === action.payload.actionId
              ? { ...item, comments: action.payload.comments }
              : item
          )
        }
      };
    }
    case UPDATE_ACTION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          actions: state.data.actions.map(item =>
            item.id === action.payload.actionId
              ? { ...item, actionStatus: action.payload.actionStatus }
              : item
          )
        }
      };
    }

    case START_FETCH_COMMON_TAGS_DATA: {
      return {
        ...state,
        commonChartData: [],
        isLoadingCommonChartData: true
      };
    }

    case FETCH_COMMON_TAGS_DATA_SUCCESS: {
      return {
        ...state,
        commonChartData: action.payload,
        isLoadingCommonChartData: false
      };
    }

    case SET_RULES_VALIDATION_ERRORS: {
      return {
        ...state,
        rulesErrors: action.payload
      };
    }

    case CLEAR_RULES_VALIDATION_ERRORS: {
      return {
        ...state,
        rulesErrors: []
      };
    }

    default:
      return state;
  }
};

export default reducer;
