export const delay = timeout => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve(null);
    }, timeout);
  });
};

export const INTERVAL_DELAY = 200;

export const waitForUserId = async state => {
  let { currentUser } = state().userReducer;

  while (!currentUser || !currentUser.id) {
    await delay(INTERVAL_DELAY);

    currentUser = state().userReducer.currentUser;
  }

  return currentUser.id;
};

export const waitForUserData = async state => {
  let { token, useremail, BLToken } = state().authReducer;

  while (!token || !BLToken) {
    await delay(INTERVAL_DELAY);

    token = state().authReducer.token;
    useremail = state().authReducer.useremail;
    BLToken = state().authReducer.BLToken;
  }

  return { token, useremail, BLToken };
};

export const waitForCurrentOrganization = async state => {
  let { currentOrganization } = state().authReducer;

  while (!currentOrganization) {
    await delay(INTERVAL_DELAY);

    currentOrganization = state().authReducer.currentOrganization;
  }

  return currentOrganization;
};

export const waitForHeaders = async state => {
  const { token, useremail, BLToken } = await waitForUserData(state);

  return generateHeaders(token, BLToken, useremail);
};

export const generateHeaders = (token, BLToken, useremail) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      token: `${BLToken}`,
      useremail
    }
  };
};
