import { useCallback, useEffect } from 'react';

import { GlobalStyle } from '@bljs/blui';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { CenteredWrapper } from 'app/components/CenteredWrapper';
import { LoadingIndicator } from 'app/components/LoadingIndicator/LoadingIndicator';
import { APP } from 'app/content/constants';
import { useAuth } from 'app/contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { lazyLoad } from 'utils/loadable';

import { REDIRECT_URL_KEY } from '../utils/constants';
import { GetTokenSilentlyOptions } from './contexts/AuthContext';
import NavigationHOC from './HOC/NavigateHOC';
import { onCallUsersAndSetUserEmail, setTokenAndOrganization } from './store/actions/authAction';
import { getColors } from './store/actions/colorAction';
import '../styles/shared/global-styles.scss';
import { getNewNotificationsCount } from './store/actions/notificationsAction';

const UPDATE_NOTIFICATIONS_INTERVAL = 2 * 60 * 1000; // 2 minutes

const fallback = (
  <CenteredWrapper>
    <LoadingIndicator />
  </CenteredWrapper>
);

const HomePage = lazyLoad(() => import(`./pages/HomePage`), {
  fallback,
  selector: module => {
    return module['HomePage'];
  }
});

const LoginPage = lazyLoad(() => import(`./pages/LoginPage`), {
  fallback,
  selector: module => {
    return module['LoginPage'];
  }
});

const NotFoundPage = lazyLoad(() => import(`./pages/NotFoundPage`), {
  fallback,
  selector: module => {
    return module['NotFoundPage'];
  }
});

const ConceptManagementPage = lazyLoad(() => import(`./pages/ConceptManagement`), {
  fallback,
  selector: module => {
    return module['ConceptManagement'];
  }
});

const ConceptManagementTestPage = lazyLoad(() => import(`./pages/ConceptManagement/ConceptTest`), {
  fallback,
  selector: module => {
    return module['ConceptTest'];
  }
});

const ConceptViewPage = lazyLoad(() => import(`./pages/ConceptManagement/ConceptView`), {
  fallback,
  selector: module => {
    return module['ConceptView'];
  }
});

const GroupPage = lazyLoad(() => import(`./pages/GroupPage`), {
  fallback,
  selector: module => {
    return module['GroupPage'];
  }
});

const GroupForm = lazyLoad(() => import(`./pages/GroupPage/GroupForm/GroupForm`), {
  fallback,
  selector: module => {
    return module['GroupForm'];
  }
});

const KeywordGroups = lazyLoad(() => import(`./pages/KeywordGroups/KeywordGroups`), {
  fallback,
  selector: module => {
    return module['KeywordGroupsPage'];
  }
});

const Users = lazyLoad(() => import(`./pages/Users/Users`), {
  fallback,
  selector: module => {
    return module['UsersPage'];
  }
});

const UsersBulkUpload = lazyLoad(() => import(`./pages/UsersBulkUpload/UsersBulkUpload`), {
  fallback,
  selector: module => {
    return module['UsersBulkUpload'];
  }
});

const NotificationsPage = lazyLoad(() => import(`./pages/Notifications/Notifications`), {
  fallback,
  selector: module => {
    return module['NotificationsPage'];
  }
});

const AppComponent = ({
  token,
  onSetToken,
  userEmail,
  onSetUserEmail,
  colorsLoaded,
  onGetColors,
  getNewNotificationsCount
}) => {
  const { i18n } = useTranslation();
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth();
  let interval;
  useEffect(() => {
    getNewNotificationsCount();
    interval = setInterval(() => {
      getNewNotificationsCount();
    }, UPDATE_NOTIFICATIONS_INTERVAL);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!isAuthenticated && window.location.pathname !== '/login') {
    localStorage.setItem(REDIRECT_URL_KEY, window.location.pathname);
  }

  const getToken = useCallback(
    async (options?: GetTokenSilentlyOptions) => {
      try {
        const tokenresponse = await getAccessTokenSilently(options);
        onSetToken(tokenresponse);
      } catch (err) {
        console.error(err);
      }
    },
    [isAuthenticated]
  );
  const setEmail = useCallback(() => {
    if (user?.email?.length) {
      onSetUserEmail(user?.email);
    }
  }, [user?.email]);

  useEffect(() => {
    if (isAuthenticated && !token) {
      getToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && !colorsLoaded && userEmail?.length) {
      onGetColors();
    }
  }, [token, colorsLoaded, userEmail?.length]);

  if (isLoading) {
    return fallback;
  }

  if (!userEmail) {
    setEmail();
  }

  initializeIcons();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={`%s | ${APP.NAME}`}
        defaultTitle={APP.NAME}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={APP.DESCRIPTION} />
      </Helmet>

      <Routes>
        {isAuthenticated ? (
          <>
            <Route
              path="/"
              element={
                <NavigationHOC>
                  <HomePage />
                </NavigationHOC>
              }
            />
            <Route
              path="/concept/new"
              element={
                <NavigationHOC>
                  <ConceptManagementPage />
                </NavigationHOC>
              }
            />
            <Route
              path="/concept/:id/edit"
              element={
                <NavigationHOC>
                  <ConceptManagementPage />
                </NavigationHOC>
              }
            />
            <Route
              path="/concept/:id/test"
              element={
                <NavigationHOC>
                  <ConceptManagementTestPage />
                </NavigationHOC>
              }
            />
            <Route
              path="/concept/:id"
              element={
                <NavigationHOC>
                  <ConceptViewPage />
                </NavigationHOC>
              }
            />
            <Route
              path="*"
              element={
                <NavigationHOC>
                  <NotFoundPage />
                </NavigationHOC>
              }
            />
            <Route
              path="/group"
              element={
                <NavigationHOC>
                  <GroupPage />
                </NavigationHOC>
              }
            />
            <Route
              path="/group/new"
              element={
                <NavigationHOC>
                  <GroupForm />
                </NavigationHOC>
              }
            />
            <Route
              path="/keyword-groups"
              element={
                <NavigationHOC>
                  <KeywordGroups />
                </NavigationHOC>
              }
            />
            <Route
              path="/users"
              element={
                <NavigationHOC>
                  <Users />
                </NavigationHOC>
              }
            />
            <Route
              path="/users-upload"
              element={
                <NavigationHOC>
                  <UsersBulkUpload />
                </NavigationHOC>
              }
            />
            <Route
              path="/notifications"
              element={
                <NavigationHOC>
                  <NotificationsPage />
                </NavigationHOC>
              }
            />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
        <Route
          path="/login"
          element={
            <NavigationHOC>
              <LoginPage />
            </NavigationHOC>
          }
        />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
};

const mapStateToProps = ({ authReducer, colorReducer }) => ({
  token: authReducer.token,
  userEmail: authReducer.useremail,
  colorsLoaded: colorReducer.loaded
});

const mapDispatchToProps = dispatch => ({
  onSetToken: token => dispatch(setTokenAndOrganization(token)),
  onSetUserEmail: email => dispatch(onCallUsersAndSetUserEmail(email)),
  onGetColors: () => dispatch(getColors()),
  getNewNotificationsCount: () => dispatch(getNewNotificationsCount())
});
export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
