import {
  START_GETTING_ASSET,
  GET_ASSET_FAILED,
  GET_ASSET_SUCCESS,
  CLEAR_ASSET
} from '../constants';

const assetDefaultState = {
  loading: false,
  assets: [],
  adj: [],
  assetsLoaded: false
};

const reducer = (state = assetDefaultState, action) => {
  switch (action.type) {
    case START_GETTING_ASSET:
      return {
        ...state,
        loading: true,
        assetsLoaded: false
      };
    case GET_ASSET_SUCCESS: {
      const { assets, adj } = action.payload;
      return {
        ...state,
        loading: false,
        assets,
        adj,
        assetsLoaded: true
      };
    }
    case GET_ASSET_FAILED:
      return {
        ...state,
        loading: false,
        assetsLoaded: true
      };
    case CLEAR_ASSET:
      return {
        ...assetDefaultState
      };
    default:
      return state;
  }
};

export default reducer;
