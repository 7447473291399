import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeaders } from '../utils';
interface IAllUserConceptFeedbackQueryArg {
  conceptId: number;
  page: number;
  size: number;
}

const { API_BASE_URI } = window.__RUNTIME_CONFIG__;

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URI}`,
    prepareHeaders
  }),
  endpoints: builder => ({
    getAllUserConceptFeedback: builder.query({
      query: ({ conceptId, page, size }: IAllUserConceptFeedbackQueryArg) => {
        return {
          url: `/api/feedbacks/${conceptId}/users?page=${page}&size=${size}`
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newData) => {
        newData.like.content = [...currentCache.like.content, ...newData.like.content];
        newData.dislike.content = [...currentCache.dislike.content, ...newData.dislike.content];

        return newData;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    })
  })
});

export const { useGetAllUserConceptFeedbackQuery } = feedbackApi;
