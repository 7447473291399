enum tableSortOder {
  ASC = 'asc',
  DESC = 'desc'
}
const DEFAULT_SORT_COLUMN_NAME = 'lastModified';

export interface ISortItem {
  sortKey: string;
  isAscending: boolean;
}
export const getSortQuery = sortOrder =>
  sortOrder.map(
    (item: ISortItem) =>
      `${item.sortKey},${item.isAscending ? tableSortOder.ASC : tableSortOder.DESC}`
  );

// isAscending: false; - From newer to older.
export const tableDefaultSorting = { sortKey: DEFAULT_SORT_COLUMN_NAME, isAscending: false };
