import {
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_CONCEPTS_STATISTIC_SUCCESS,
  GET_CONCEPT_DETAILED_STATISTIC_SUCCESS,
  CHANGE_SHOW_ALL_NOTIFICATIONS,
  SET_NOTIFICATIONS_PAGINATION
} from '../constants';

const notificationDefaultState = {
  newNotificationsCount: 0,
  notifications: [],
  notificationsLoaded: false,
  notificationsCount: 0,
  showAllNotifications: true,
  conceptsStatistic: [],
  conceptsStatisticCount: 0,
  conceptDetailedStatistic: {},
  notificationPagination: 0
};

const reducer = (state = notificationDefaultState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        newNotificationsCount: action.payload
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.content,
        notificationsCount: action.payload.totalElements,
        notificationsLoaded: true
      };
    case CHANGE_SHOW_ALL_NOTIFICATIONS:
      return {
        ...state,
        showAllNotifications: action.payload
      };
    case GET_CONCEPTS_STATISTIC_SUCCESS:
      return {
        ...state,
        conceptsStatistic: action.payload.content,
        conceptsStatisticCount: action.payload.totalElements
      };
    case GET_CONCEPT_DETAILED_STATISTIC_SUCCESS:
      return {
        ...state,
        conceptDetailedStatistic: action.payload
      };
    case SET_NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        notificationPagination: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
