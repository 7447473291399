import {
  FETCH_RELATED_EVENTS_FAILED,
  FETCH_RELATED_EVENTS_SUCCESS,
  START_FETCH_RELATED_EVENTS
} from '../constants';

const conceptRelatedEventsDefaultState = {
  loading: false,
  items: [],
  totalItems: 0
};

const reducer = (state = conceptRelatedEventsDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_RELATED_EVENTS:
      return {
        ...state,
        loading: true
      };
    case FETCH_RELATED_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.items,
        totalItems: action.payload.totalItems
      };
    case FETCH_RELATED_EVENTS_FAILED:
      return {
        ...conceptRelatedEventsDefaultState
      };

    default:
      return state;
  }
};

export default reducer;
