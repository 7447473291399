import { createElement, ReactElement } from 'react';

import {
  CustomButton,
  ToastErrorWrapper
} from 'app/components/ToastErrorWrapper/ToastErrorWrapper';
import { toast } from 'react-toastify';

export const pluralizeNoun = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const stripHtmlTags = htmlText => htmlText.replace(/<[^>]*>/g, '');

export const isMac = () => {
  return navigator.userAgent.indexOf('Mac OS X') !== -1;
};

enum ToastColors {
  ERROR = '#e74c3c',
  WARNING = '#f1c40f',
  SUCCESS = '#07bc0c'
}

export const displayToastError = (
  message: string | ReactElement | JSX.Element,
  serviceError: Record<string, any> | unknown = {}
) => {
  const content = createElement(ToastErrorWrapper, { message, serviceError });
  const CloseButton = () => <CustomButton background={ToastColors.ERROR} />;

  toast.error(content, { autoClose: false, closeOnClick: false, closeButton: CloseButton });
};

export const displayToastWarning = (
  message: string | ReactElement | JSX.Element,
  serviceError: Record<string, any> | unknown = {}
) => {
  const content = createElement(ToastErrorWrapper, { message, serviceError });
  const CloseButton = () => <CustomButton background={ToastColors.WARNING} />;

  toast.warn(content, { autoClose: false, closeOnClick: false, closeButton: CloseButton });
};

export const displayToastSuccess = (
  message: string | ReactElement | JSX.Element,
  serviceError: Record<string, any> | unknown = {}
) => {
  const content = createElement(ToastErrorWrapper, { message, serviceError });
  const CloseButton = () => <CustomButton background={ToastColors.SUCCESS} />;

  toast.success(content, { autoClose: false, closeOnClick: false, closeButton: CloseButton });
};
