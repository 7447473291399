import { displayToastError, displayToastSuccess } from '../../../utils/common/helpers';
import { waitForHeaders } from '../../../utils/createAuthHeaders';
import { http } from '../../BaseApi';
import {
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  START_GETTING_USERS,
  SET_CURRENT_USER,
  SET_SELECTED_USER,
  SET_USERS_ROLES,
  SET_ORGANIZATIONAL_UNITS,
  SET_USERS_UPLOAD_RESULT
} from '../constants';
import { defaultSelectedUser, defaultUploadResult } from '../reducers/userReducer';
import { closePopup } from './popupAction';

export enum LoadUserType {
  ALL = 'all',
  ORGANIZATION = 'organization',
  UNIT = 'unit'
}

const getUsersFailed = () => ({
  type: GET_USERS_FAILED
});

const startGettingUsers = () => ({
  type: START_GETTING_USERS
});

const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users
});

const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user
});

// I am not sure we have to use this users at all.
export const getUsers = (loadUserType: LoadUserType) => {
  return async (dispatch, getState) => {
    try {
      const currentUserEmail = getState().authReducer.useremail;

      dispatch(startGettingUsers());
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/users?loadType=${loadUserType}`, headers);

      dispatch(getUsersSuccess(res.data));
      const currentUser = res.data.find(item => item.emailAddress === currentUserEmail);

      if (currentUser) {
        dispatch(setCurrentUser(currentUser));
      }
    } catch (err) {
      displayToastError('Could not retrieve users.', err);
      dispatch(getUsersFailed());
    }
  };
};

const setSelectedUser = user => ({
  type: SET_SELECTED_USER,
  payload: user
});

export const getSelectedUserById = id => {
  return async (dispatch, getState) => {
    try {
      if (!id) {
        dispatch(setSelectedUser(defaultSelectedUser));
      } else {
        const headers = await waitForHeaders(getState);
        const res = await http.get(`/api/user/${id}`, headers);

        dispatch(setSelectedUser(res.data));
      }
    } catch (err) {
      displayToastError('Unable to retrieve user details.', err);
    }
  };
};

export const addUser = (newUser, callback) => {
  return async (dispatch, getState) => {
    try {
      const organizationId = getState().authReducer.currentOrganization.organization.id;

      const body = {
        ...newUser,
        organizationId,
        defaultOrganizationalUnit: newUser.defaultOrganizationalUnit.id,
        organizationalUnits: [
          ...newUser.organizationalUnits.map(item => item.id),
          newUser.defaultOrganizationalUnit.id
        ],
        roles: newUser.roles.map(item => item.id)
      };
      const headers = await waitForHeaders(getState);
      const res = await http.post(`/api/user`, body, headers);

      const { users } = getState().userReducer;
      const updatedUsers = [...users, res.data];
      dispatch(getUsersSuccess(updatedUsers));
      dispatch(getSelectedUserById(null));
      callback();
      displayToastSuccess('You successfully added a new user.');
    } catch (err) {
      displayToastError('Unable to add user.', err);
    }
  };
};

export const updateUser = updatedUser => {
  return async (dispatch, getState) => {
    try {
      const body = {
        ...updatedUser,
        defaultOrganizationalUnit: updatedUser.defaultOrganizationalUnit.id,
        organizationalUnits: updatedUser.organizationalUnits.map(item => item.id),
        roles: updatedUser.roles.map(item => item.id)
      };
      const headers = await waitForHeaders(getState);
      const res = await http.put(`/api/user/${updatedUser.id}`, body, headers);

      const { users } = getState().userReducer;
      const updatedUsers = users.map(user => (user.id === updatedUser.id ? res.data : user));
      dispatch(getUsersSuccess(updatedUsers));
    } catch (err) {
      displayToastError('Unable to update user.', err);
    }
  };
};

export const removeUser = id => {
  return async (dispatch, getState) => {
    try {
      const { users } = getState().userReducer;
      const headers: any = await waitForHeaders(getState);
      headers.headers['Content-Type'] = 'multipart/form-data';
      await http.delete(`/api/user/${id}`, headers);

      const updatedUsers = users.filter(user => user.id !== id);
      dispatch(getUsersSuccess(updatedUsers));
      dispatch(setSelectedUser(defaultSelectedUser));

      dispatch(closePopup());
    } catch (err) {
      displayToastError('Unable to remove user.', err);
    }
  };
};

const setUploadResult = uploadResult => ({
  type: SET_USERS_UPLOAD_RESULT,
  payload: uploadResult
});

export const uploadFile = file => {
  return async (dispatch, getState) => {
    try {
      dispatch(setUploadResult(defaultUploadResult));

      const headers = await waitForHeaders(getState);
      const formData = new FormData();
      formData.append('file', file);

      const result = await http.post('/api/user/upload', formData, headers);

      dispatch(setUploadResult(result.data));
    } catch (err) {
      displayToastError('Unable to upload user.', err);
    }
  };
};

const setUsersRoles = roles => ({
  type: SET_USERS_ROLES,
  payload: roles
});

export const getUsersRoles = () => {
  return async (dispatch, getState) => {
    try {
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/roles`, headers);

      dispatch(setUsersRoles(res.data));
    } catch (err) {
      displayToastError('Unable to retrieve users roles.', err);
    }
  };
};

const setOrganizationalUnits = units => ({
  type: SET_ORGANIZATIONAL_UNITS,
  payload: units
});

export const getOrganizationalUnits = () => {
  return async (dispatch, getState) => {
    try {
      const headers = await waitForHeaders(getState);
      const res = await http.get(`/api/organizational-units`, headers);

      dispatch(setOrganizationalUnits(res.data));
    } catch (err) {
      displayToastError('Unable to retrieve Organizational Units.', err);
    }
  };
};
