import {
  SET_TOKEN,
  CLEAR_TOKEN,
  SET_USER_EMAIL,
  SET_CURRENT_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_BEYOND_LIMITS_TOKEN,
  SET_BEYOND_LIMITS_ROLES
} from '../constants';

const authDefaultState = {
  token: '',
  useremail: '',
  currentOrganization: '',
  organizations: [],
  BLToken: '',
  roles: []
};

const reducer = (state = authDefaultState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case CLEAR_TOKEN:
      return authDefaultState;
    case SET_USER_EMAIL:
      return {
        ...state,
        useremail: action.payload
      };
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload
      };
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };
    case SET_BEYOND_LIMITS_TOKEN:
      return {
        ...state,
        BLToken: action.payload
      };
    case SET_BEYOND_LIMITS_ROLES:
      return {
        ...state,
        roles: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
