import {
  START_GETTING_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  SET_CURRENT_USER,
  SET_SELECTED_USER,
  SET_USERS_ROLES,
  SET_ORGANIZATIONAL_UNITS,
  SET_USERS_UPLOAD_RESULT
} from '../constants';

export const defaultSelectedUser = {
  id: null,
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  jobTitle: '',
  location: '',
  notifyByEmail: false,
  notifyByPhone: false,
  roles: [],
  defaultOrganizationalUnit: { id: null },
  organizationalUnits: []
};

export const defaultUploadResult = {
  duplicate: [],
  errors: [],
  processed: []
};

const userDefaultState = {
  loading: false,
  users: [],
  roles: [],
  currentUser: {},
  selectedUser: defaultSelectedUser,
  organizationalUnits: [],
  uploadResult: defaultUploadResult
};

const reducer = (state = userDefaultState, action) => {
  switch (action.type) {
    case START_GETTING_USERS:
      return {
        ...state,
        loading: true,
        users: []
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        loading: false
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case SET_USERS_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case SET_ORGANIZATIONAL_UNITS:
      return {
        ...state,
        organizationalUnits: action.payload
      };
    case SET_USERS_UPLOAD_RESULT:
      return {
        ...state,
        uploadResult: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
