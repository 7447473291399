import { http } from '../../BaseApi';
import {
  SET_TOKEN,
  CLEAR_TOKEN,
  SET_USER_EMAIL,
  SET_CURRENT_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_BEYOND_LIMITS_TOKEN,
  SET_BEYOND_LIMITS_ROLES
} from '../constants';
import { getUsers, LoadUserType } from './userAction';

export const setToken = token => ({
  type: SET_TOKEN,
  payload: token
});

export const setBeyondLimitsToken = token => ({
  type: SET_BEYOND_LIMITS_TOKEN,
  payload: token
});

export const setBeyondLimitsRoles = roles => ({
  type: SET_BEYOND_LIMITS_ROLES,
  payload: roles
});

export const setCurrentOrganization = token => ({
  type: SET_CURRENT_ORGANIZATION,
  payload: token
});

export const setOrganizations = organizations => ({
  type: SET_ORGANIZATIONS,
  payload: organizations
});

export const changeOrganization = organization => {
  return async dispatch => {
    dispatch(setCurrentOrganization(organization));
    localStorage.setItem('CURRENT_ORG', JSON.stringify(organization));
    window.location.replace('/');
  };
};

export const setTokenAndOrganization = token => {
  return async (dispatch, getState) => {
    try {
      const userEmail = getState().authReducer.useremail;
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
          useremail: userEmail
        }
      };

      const organizations = await http.get(`/api/organizations?email=${userEmail}`, headers);
      dispatch(setOrganizations(organizations.data));
      if (localStorage.getItem('CURRENT_ORG')) {
        const currentOrg = JSON.parse(localStorage.getItem('CURRENT_ORG') || '');
        const tokenResponse = await http.post(
          `/api/token`,
          {
            unitId: currentOrg.id,
            userEmail,
            orgId: currentOrg.organization.id,
            unitCode: currentOrg.code,
            orgCode: currentOrg.organization.code,
            userId: currentOrg.userId
          },
          headers
        );

        dispatch(setBeyondLimitsToken(tokenResponse.data.token));
        dispatch(setBeyondLimitsRoles(tokenResponse.data.roles));
        dispatch(setCurrentOrganization(currentOrg));
      } else {
        const tokenResponse = await http.post(
          `/api/token`,
          {
            unitId: organizations.data[0].id,
            userEmail,
            orgId: organizations.data[0].organization.id,
            unitCode: organizations.data[0].code,
            orgCode: organizations.data[0].organization.code,
            userId: organizations.data[0].userId
          },
          headers
        );
        localStorage.setItem('CURRENT_ORG', JSON.stringify(organizations.data[0]));
        dispatch(setBeyondLimitsToken(tokenResponse.data.token));
        dispatch(setBeyondLimitsRoles(tokenResponse.data.roles));
        dispatch(setCurrentOrganization(organizations.data[0]));
      }

      dispatch(setToken(token));
    } catch (e) {}
  };
};
export const clearToken = () => ({
  type: CLEAR_TOKEN
});

export const setUserEmail = useremail => ({
  type: SET_USER_EMAIL,
  payload: useremail
});

export const onCallUsersAndSetUserEmail = useremail => {
  return async dispatch => {
    dispatch(setUserEmail(useremail));
    dispatch(getUsers(LoadUserType.UNIT));
  };
};
