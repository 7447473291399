import { OPEN_CONFIRM_POPUP, CLOSE_CONFIRM_POPUP } from '../constants';

export const openPopup = (title, message, callback) => ({
  type: OPEN_CONFIRM_POPUP,
  payload: { title, message, callback }
});

export const closePopup = () => ({
  type: CLOSE_CONFIRM_POPUP
});
