import { INTERVAL_DELAY, delay } from 'utils/createAuthHeaders';

export const prepareHeaders = async (headers: Headers, { getState }) => {
  const { token, useremail, BLToken } = (getState() as any).authReducer;

  headers.set('useremail', useremail);
  headers.set('content-type', 'application/json');

  while (!token || !BLToken) {
    await delay(INTERVAL_DELAY);

    headers.set('token', (getState() as any).authReducer.BLToken);
    headers.set('authorization', `Bearer ${(getState() as any).authReducer.token}`);
  }

  if (token && !headers.has('token')) {
    headers.set('token', BLToken);
  }

  if (BLToken && !headers.has('BLToken')) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
