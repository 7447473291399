import { useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { AuthContext } from '.';

export default function useAuth() {
  const { hookUseAuthValues } = useContext(AuthContext);
  // return useAuth0 if we are in production or are not skipping auth
  const auth0Context = useAuth0();

  if (
    process.env.NODE_ENV === 'production' ||
    !JSON.parse(window.__RUNTIME_CONFIG__.DANGER_SKIP_AUTH || 'false')
  ) {
    return auth0Context;
  }
  return hookUseAuthValues;
}
