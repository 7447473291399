import { START_GETTING_COLORS, GET_COLORS_FAILED, GET_COLORS_SUCCESS } from '../constants';

const colorDefaultState = {
  loading: false,
  colors: {},
  loaded: false
};

const reducer = (state = colorDefaultState, action) => {
  switch (action.type) {
    case START_GETTING_COLORS:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_COLORS_SUCCESS: {
      const colorsArray = action.payload;
      const colors = {};
      for (const color of colorsArray) {
        colors[color.id] = color.rgbHexCode;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        colors
      };
    }
    case GET_COLORS_FAILED:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export default reducer;
