import { IGroup } from '../../pages/GroupPage/types';
import {
  CLEAN_GROUP_LIST,
  CREATE_GROUP_SUCCESS,
  FETCH_GROUP_LIST_FAILED,
  FETCH_GROUP_LIST_SUCCESS,
  FETCH_RELATED_GROUP_LIST_SUCCESS,
  FETCH_SIMILAR_GROUP_LIST_SUCCESS,
  GROUP_UNSUBSCRIBED,
  RELATED_GROUP_SUBSCRIBED,
  RELATED_GROUP_UNSUBSCRIBED,
  SIMILAR_GROUP_SUBSCRIBED,
  SIMILAR_GROUP_UNSUBSCRIBED,
  START_CREATE_GROUP,
  START_FETCH_GROUP_LIST
} from '../constants';

const groupDefaultState = {
  loading: false,
  firstLoad: false,
  groups: [],
  numberOfElements: 0,
  relatedGroups: [],
  numberOfRelatedGroups: 0,
  createGroupResponse: {},
  similarGroups: [],
  numberOfSimilarGroups: 0
};

const reducer = (state = groupDefaultState, action) => {
  switch (action.type) {
    case START_FETCH_GROUP_LIST:
      return {
        ...state,
        groups: [],
        loading: true
      };
    case FETCH_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        firstLoad: true,
        groups: action.payload,
        numberOfElements: action.payload.length
      };
    case FETCH_GROUP_LIST_FAILED:
      return {
        ...state,
        loading: false,
        firstLoad: true
      };
    case CLEAN_GROUP_LIST:
      return groupDefaultState;
    case FETCH_RELATED_GROUP_LIST_SUCCESS:
      return {
        ...state,
        relatedGroups: action.payload,
        numberOfRelatedGroups: action.payload.length
      };
    case START_CREATE_GROUP:
      return {
        ...state,
        createGroupResponse: {}
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createGroupResponse: action.payload
      };
    case FETCH_SIMILAR_GROUP_LIST_SUCCESS:
      const groups = action.payload.groups;
      return {
        ...state,
        similarGroups: groups,
        numberOfSimilarGroups: groups.length
      };
    case SIMILAR_GROUP_SUBSCRIBED:
      return {
        ...state,
        similarGroups: state.similarGroups.map((group: IGroup) =>
          group.name === action.payload ? { ...group, subscribed: true } : group
        )
      };
    case RELATED_GROUP_UNSUBSCRIBED:
      return {
        ...state,
        groups: state.groups.filter((group: IGroup) => group.name !== action.payload),
        relatedGroups: state.relatedGroups.map((group: IGroup) =>
          group.name === action.payload ? { ...group, subscribed: false } : group
        )
      };
    case RELATED_GROUP_SUBSCRIBED:
      const group = state.relatedGroups.find((group: IGroup) => group.name === action.payload);
      return {
        ...state,
        groups: [...state.groups, group],
        relatedGroups: state.relatedGroups.map((group: IGroup) =>
          group.name === action.payload ? { ...group, subscribed: true } : group
        )
      };
    case SIMILAR_GROUP_UNSUBSCRIBED:
      return {
        ...state,
        similarGroups: state.similarGroups.map((group: IGroup) =>
          group.name === action.payload ? { ...group, subscribed: false } : group
        )
      };
    case GROUP_UNSUBSCRIBED:
      return {
        ...state,
        groups: state.groups.filter((group: IGroup) => group.name !== action.payload)
      };
    default:
      return state;
  }
};

export default reducer;
